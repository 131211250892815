var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-main",
    [
      _c(
        "v-container",
        { attrs: { fluid: "", "fill-height": "" } },
        [
          _c(
            "v-layout",
            { attrs: { "align-center": "", "justify-center": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "", sm8: "", md4: "" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "elevation-12" },
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { dark: "", color: "primary" } },
                        [
                          _c("v-toolbar-title", [
                            _vm._v("Esqueceu sua senha?")
                          ]),
                          _c("v-spacer")
                        ],
                        1
                      ),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-form",
                            { ref: "form", attrs: { "lazy-validation": "" } },
                            [
                              _c(
                                "p",
                                { staticClass: "text-justify caption mb-7" },
                                [
                                  _vm._v(
                                    "Informe seu email cadastrado. Você receberá informações nesse email para alterar sua senha."
                                  )
                                ]
                              ),
                              _c("v-text-field", {
                                staticClass: "pt-0",
                                attrs: { label: "Email", autofocus: "" },
                                model: {
                                  value: _vm.email,
                                  callback: function($$v) {
                                    _vm.email = $$v
                                  },
                                  expression: "email"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.message
                        ? _c(
                            "v-alert",
                            {
                              staticClass: "ma-2",
                              attrs: { type: _vm.error ? "error" : "success" }
                            },
                            [_vm._v(" " + _vm._s(_vm.message) + " ")]
                          )
                        : _vm._e(),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-2",
                              staticStyle: { cursor: "pointer" },
                              attrs: {
                                color: "primary",
                                disabled: _vm.loading || !_vm.email,
                                loading: _vm.loading
                              },
                              on: {
                                click: function($event) {
                                  return _vm.send()
                                }
                              }
                            },
                            [_vm._v("Enviar")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ml-1 mb-1",
                          attrs: { small: "", text: "", color: "green" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              $event.stopPropagation()
                              return _vm.login()
                            }
                          }
                        },
                        [_vm._v("IR PARA LOGIN")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }