import { mapActions } from "vuex";

export default {
    data: () => ({
        message: '',
        error: false,
        loading: false,
        email: '',
        emailRules: [ 
            v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail inválido'
          ]
    }),
    methods: {
        ...mapActions('auth', ['ActionSendMailResetPassword']),
        login() {
            this.$router.push({ name: 'login' });
        },
        send() {
            this.loading = true
            this.error = false
            this.message = ''

            this.ActionSendMailResetPassword({ email: this.email })
                .then((r) => {
                    if(r.success) {
                        this.message = r.message
                        
                        setTimeout(() => {  
                            this.$router.push({ name: 'login' });
                         }, 10000);

                    } else {
                        this.message = r.message
                        this.error = true
                    }
                })
                .catch((e) => {
                    this.message = ''
                })
                .finally(()=>{
                    this.loading = false
                })
        }
    }
}