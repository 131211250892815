var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-main",
    [
      _c(
        "v-container",
        { attrs: { fluid: "", "fill-height": "" } },
        [
          _c(
            "v-layout",
            { attrs: { "align-center": "", "justify-center": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "", sm8: "", md4: "" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "elevation-12" },
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { dark: "", color: "primary" } },
                        [
                          _c("v-toolbar-title", [_vm._v("Alteração de senha")]),
                          _c("v-spacer")
                        ],
                        1
                      ),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-form",
                            { ref: "form", attrs: { "lazy-validation": "" } },
                            [
                              _c(
                                "p",
                                { staticClass: "text-justify caption mb-7" },
                                [_vm._v("Informe sua nova senha:")]
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          type: "password",
                                          dense: "",
                                          error: _vm.errors.length > 0,
                                          "error-messages": _vm.errors.password,
                                          label: "Senha"
                                        },
                                        model: {
                                          value: _vm.password,
                                          callback: function($$v) {
                                            _vm.password = $$v
                                          },
                                          expression: "password"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          type: "password",
                                          dense: "",
                                          error: _vm.errors.length > 0,
                                          "error-messages":
                                            _vm.errors.password_confirmation,
                                          label: "Confirmar senha"
                                        },
                                        model: {
                                          value: _vm.password_confirmation,
                                          callback: function($$v) {
                                            _vm.password_confirmation = $$v
                                          },
                                          expression: "password_confirmation"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "12" } }, [
                                    _c(
                                      "p",
                                      {
                                        staticClass: "text-justify caption mb-7"
                                      },
                                      [
                                        _c("strong", [
                                          _vm._v(
                                            "*A senha deve conter no mínimo 8 caracteres, composta por letras, números e pelo menos 1 caracter maísculo e um especial"
                                          )
                                        ])
                                      ]
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.message
                        ? _c(
                            "v-alert",
                            {
                              staticClass: "ma-2",
                              attrs: { type: _vm.error ? "error" : "success" }
                            },
                            [_vm._v(" " + _vm._s(_vm.message) + " ")]
                          )
                        : _vm._e(),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-2",
                              staticStyle: { cursor: "pointer" },
                              attrs: {
                                color: "primary",
                                disabled: _vm.loading,
                                loading: _vm.loading
                              },
                              on: {
                                click: function($event) {
                                  return _vm.send()
                                }
                              }
                            },
                            [_vm._v("Alterar senha")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }